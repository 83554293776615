.sp-main-nav .sp-main-nav-left {
  height: 40px;
  line-height: 40px;
  background-color: #002140;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sp-main-nav{
  background-color: #002140;
  color: white;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.sp-main-nav-left{
  line-height: 40px;
  display: flex;
  margin-left: 20px
}

.svg-inline--fa.fa-w-16{
  width: 28px;
  height: 28px;
}

.sp-main-nav-left h1 {
  font-size: 150%;
  font-weight: 150;
  margin-left: 5px;
  padding: 0px 5px;
  margin-bottom: 0px;
}

.sp-main-nav-right a {
  font-size: 110%;
  font-weight: 150; 
  padding-right: 20px;
}

.sp-main-nav-middle{
  display: flex;
}
.sp-main-nav-middle h3{
  font-size: 125%;
  font-weight: 200;
  margin-left: 5px;
  padding: 0px 5px;
  margin-bottom: 0px;
}

.sp-main-nav-middle a{
  background: lightgray;
  color: #002140;
  margin-left: 10px;
  padding: 0px 5px;
  border-radius: 5px;
  transition: all .5s;
}

.sp-main-nav-middle a:hover{
  color: #002140;
  background: white;
}