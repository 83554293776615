#rw-list {
  text-decoration: none;
}
#rw_1_listbox {
  text-decoration: none;
  padding: 15px;
  text-decoration: none;
  padding: 15px;
  background-color: lightslategrey;
  border-radius: 10px;
  list-style-type: none;
  color: black;
}
input.rw-select-list-input {
  margin-right: 25px;
}

li.rw-list-option {
  border: solid 2px antiquewhite;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

input[type="radio"] {
  margin-right: 400px;
}

#rw_3_taglist {
  list-style-type: none;
  display: flex;
  justify-content: center;
}
#rw_1_taglist {
  list-style: none;
  display: flex;
  justify-content: center;
}

button.rw-multiselect-tag-btn {
  background-color: grey;
  color: white;
  border-radius: 10px;
}

.rw-multiselect-tag {
  margin: 3px;
  padding: 3px;
}

input.rw-input-reset {
  width: 85%;
  border-radius: 50px;
  padding: 4px;
  font-size: 1.5em;
  margin-bottom: 2.5em;
}

span.rw-select {
  display: none;
}

/* REACT SELECT */
.css-2b097c-container {
  width: 100% !important;
  color: black;
  margin-bottom: 2vw;
}
.css-yk16xz-control,
.css-1pahdxg-control {
  padding: 7px !important;
  border-radius: 50px !important;
}
