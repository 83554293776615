.sp-admin-events-container{
    flex-wrap: wrap;
    display: flex;
    padding: 2vh 6vh;
}

.sp-admin-events-container-add-event-button {
    vertical-align: middle;
    border: 1px solid #d3d3d3;
    border-radius: 2vh;
    padding: 6vh 3vh;
    transition: all .3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #0d62a3;
    font-size: 8vh;
    margin: 4vh;
}