.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    background: #ecf3f4;
  
    width: 100%;
    height: 95%;
    max-width: 480px;
  
    border-radius: 16px;
  
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 1.5px 4px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1.5px 4px 8px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 1.5px 4px 8px 0px rgba(0, 0, 0, 0.15);
  
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  
    cursor: pointer;
    user-select: none;
}
  
.cardContainer::-webkit-scrollbar {
    display: none;
}
  
.imgContainer {
    position: relative;
    min-width: 100%;
}
  
.imgContainer::-webkit-scrollbar {
    display: none;
}
  
img {
    width: 100%;
    /*max-height: 100%;*/
}
  
.song-info-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
  
    background: #ecf3f4d2;
    padding: 16px;
  
    position: absolute;
    bottom: 0;
}
  
.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    box-sizing: border-box;
    width: 90%;
    height: 40%;
    max-width: 400px;
    max-height: 480px;
  
    padding: 16px;
    overflow-y: scroll;
  
    position: relative;
}
  
.song {
    color: rgba(0, 146, 124, 1);
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
    margin-bottom: 4px;
}
  
.artist {
    color: rgba(0, 146, 124, 0.75);
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
    margin-bottom: 8px;
}
  
.submittedByLabel {
    color: rgba(162, 170, 173, 1);
    font-size: 12px;
    font-weight: bold;
    text-align: right;
  
    margin-bottom: 4px;
}
.submissionBy {
    color: rgba(91, 103, 112, 1);
    font-size: 14px;
    font-weight: bold;
    text-align: right;
  
    margin-bottom: 4px;
}
  
.location {
    color: rgba(91, 103, 112, 1);
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}
  
/*reason */
.descriptionContainer {
    /*background: rgba(91,103,112,.1);*/
    min-height: 48px;
  
    padding: 8px;
    overflow-y: scroll;
  
    color: rgba(91, 103, 112, 1);
    font-size: 16px;
    font-style: italic;
    text-align: left;
  
    border-radius: 4px;
    display: flex;
    justify-content: center;
}
  
.reason {
    display: inline;
}
@media (min-width: 900px) {


}
