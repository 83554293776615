a {
    height: 100%;
    cursor: pointer;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    transition: all 0.3s;
    color: white;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: all 1s ease;
    transition:  all .5s ease;
}

a:hover {
    text-decoration: none;
    color: white;
    filter: brightness(60%);
}
.spotify-app{
    height: 100vh;
}