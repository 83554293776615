#searchContainer {
  position: inherit;
  overflow: scroll;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
  border-radius: 10px;
}
#searchItemContainer {
  /* border: 5px solid gray; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.5vw 0;
}
#searchItemContainer > *:nth-child(2) {
  flex: 1;
  margin-left: 1vw;
}
#searchItemText p {
  margin: 0;
}
#song {
  font-weight: 500;
  font-size: 1em;
}
#artist {
  font-size: 0.8em;
}
