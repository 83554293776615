.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -2;
}

.playlistContainer {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-y: hidden;
}

.wrapperContainer {
  height: 80vh;
  position: relative;
}

.uiButtonContainer {
  max-width: 480px;
  padding-top: 5vh;
  margin: auto;
  height: 20vh;
}

.uiButton {
  display: flex;
  justify-content: center;
  width: 100%;

  background: #ff893b;
  color: white !important;

  border: 2px solid #fd7820;
  border-radius: 4px;
  padding: 3%;

  font-size: 14px;
  text-align: center;

  -webkit-box-shadow: 1.5px 3px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1.5px 3px 8px 0px rgba(0, 0, 0, 0.15);
  transition: all .5s;
}

.uiButton:hover{
    background: #fd7820;
    transform: scale(1.075);
    text-decoration: none;
}

.uiButtonsSpotifySubmit {
  display: flex;
  margin-top: 12px;
}

.uiButtonsSpotifySubmit a:first-child{
  margin-right: 12px;
}

.nextButton {
  background:  rgba(0, 146, 124, 1);
  border: 2px solid rgb(1, 139, 118);
}

.nextButton:hover {
  background:  rgb(1, 139, 118);
}
@media (max-width: 900px) {
  .uiButtonContainer {
    padding-top: 0vh;
  }
  .playlistContainer {
    padding: 4vh 10vw;
  }
  .wrapperContainer {
    height: 75vh;
  }
}
  
@media (min-width: 900px) {
  .nextButton {
    display: none;
  }

  .cardContainer {
    max-width: 360px;
    margin-top: 6vh;
  }

  .song-info-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;

    background: rgba(255, 255, 255, 0.85);
    padding: 16px 32px;

    position: absolute;
    bottom: 0;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    width: 90%;
    height: 40%;
    max-width: 400px;
    max-height: 480px;

    padding: 16px;

    position: relative;
    overflow-y: hidden;
  }

  .song {
    font-size: 16px;
  }

  .artist {
    font-size: 12px;
  }

  .submittedByLabel {
    color: rgba(162, 170, 173, 1);
    font-size: 12px;
    font-weight: bold;
    text-align: right;

    margin-bottom: 4px;
  }
  .submissionBy {
    color: rgba(91, 103, 112, 1);
    font-size: 16px;
    font-weight: bold;
    text-align: right;

    margin-bottom: 4px;
  }
  /* 	
    .location {
        color: rgba(91,103,112,1);
        font-size: 12px;
        font-weight: bold;
        text-align: right;
    } */

  /*reason */
  .descriptionContainer {
    /*background: rgba(91,103,112,.1);*/
    min-height: 48px;

    padding: 8px;
    overflow-y: hidden;

    color: rgba(91, 103, 112, 1);
    font-size: 14px;
    font-style: italic;
    text-align: left;

    border-radius: 4px;

    margin-bottom: 24px;
  }
}
  
